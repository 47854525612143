import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import it2BgImg from '../../assets/img/it2/keyboard-analytics-cropped.png';
// import scctBgImg from '../../assets/img/it2/control-tower.jpg';
// import vistaBgImg from  '../../assets/img/it2/vista.jpg';

const bannerData = [
    {
        id: "1",
        SlideBgImg: `${it2BgImg}`,
        TopTitle_pre: "Strategy ",
        TopTitle_post: " Execution",
        Title: "Technology and Data Services",
        BtnLink:  "#about",
        BtnName: "get in touch"
    },
    // {
    //     id: "2",
    //     SlideBgImg: `${vistaBgImg}`,
    //     BtnLink:  "/single-version-of-the-truth#navbar",
    //     BtnName: "See Our Impact",
    // },
    // {
    //     id: "3",
    //     SlideBgImg: `${scctBgImg}`,
    //     BtnLink:  "/supply-chain-control-tower#navbar",
    //     BtnName: "See Our Impact",
    // }
];

export default function BannerOne() {
  return (
    <>
      <Swiper
        id="home"
        navigation={true}
        modules={[Navigation]}
        className="homepage-slides"
      >
        {bannerData && bannerData.map((banner) => (
            <SwiperSlide key={banner.id}>
{ banner.id === "1" ? (
<>
<div
    className="single-slider-item"
    style={{ backgroundImage: `url(${banner.SlideBgImg})` }}
>
    <div className="display-table">
      <div className="display-table-cell">
        <div className="container">
          <div className="row">
            <div className="col-lg-7  ">
              <div className="winning-story-info">

                <h1 >{banner.TopTitle_pre} <i className="icofont-long-arrow-right" /> {banner.TopTitle_post}</h1>
                <h3>{banner.Title}</h3>
                <div className="center-wrap">
                    <div className="button">
                        <a href={banner.BtnLink}>{banner.BtnName} <i className="icofont-long-arrow-right"></i></a>
                        <div className="mask"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</div>
</>
) : ( <> </> )}
{ //Optimizing Drug Availability
 banner.id === "2" ? (
<>
<div
    className="single-slider-item  winning-story-vista-bg-img">"
>
                  <div className="display-table">
                    <div className="display-table-cell">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-4 pb-100">
                            <div className="winning-story-info">
                              <h1 className="white">Solving for a single version of the truth</h1>
                              <h3 className="white">Achieving performance and operational excellence across a community of stakeholders</h3>
                              <div className="center-wrap">
                                  <div className="button">
                                      <a href={banner.BtnLink}>{banner.BtnName} <i className="icofont-long-arrow-right"></i></a>
                                      <div className="mask"></div>
                                  </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

</div>
</>
) : ( <> </> )}
{ banner.id === "3" ? (
<>
<div
    className="single-slider-item winning-story-ct-bg-img"
>                  <div className="display-table">
                    <div className="display-table-cell">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-4  ">
                            <div className="winning-story-info">
                              <h1 className="black">Setting a new bar for global supply chain execution and visibility</h1>
                              <h3 className="black">A Supply Chain Control Tower</h3>
                              <div className="center-wrap">
                                  <div className="button">
                                      <a href={banner.BtnLink}>{banner.BtnName} <i className="icofont-long-arrow-right"></i></a>
                                      <div className="mask"></div>
                                  </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

</div>
</>
) : ( <> </> )}

            </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
