import React, { Component } from 'react';

// Import Component
import NavBar from "../components/NavBar";
// import BannerThree from "../components/banner/BannerThree";
import BannerOne from "../components/banner/BannerOne";
import Services from "../components/Services";
import About from "../components/About";
// import TeaserIntro from "../components/TeaserIntro";
import SVOTTeaser from "../components/SVOTTeaser";
import SCCTTeaser from "../components/SCCTTeaser";
import Footer from "../components/Footer";

class HomeOne extends Component {
  render() {
    return (
        <>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar pageName="home" />

            {/* BannerOne: src/components/banner/BannerOne */}
            <BannerOne />

            {/* Services: src/components/Services.jsx */}
            <Services />

            <SVOTTeaser />
            <SCCTTeaser />

            {/* About: src/components/About.jsx */}
            <About />

            {/* Footer: src/components/Footer.jsx */}
            <Footer />
        </>
    );
  }
}

export default HomeOne;
