import React, { Component } from 'react';

// Import Component
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";


class SVOT extends Component {
    render() {
          return (
              <>
              <style>
              {`
                h1  {
                  font-size: 2em;
                }
              `}
              </style>
                  {/* NavBar: src/components/NavBar.jsx */}
                  <NavBar pageName="single-version-of-the-truth" />

                  <div id="top"
                      className="single-slider-item winning-story-vista-bg-img"
                  >
                  <div className="display-table">
                    <div className="display-table-cell">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-4  pb-200">
                            <div className="winning-story-info">
                              <h1 className="white">Solving for a single version of the truth</h1>
                              <h3 className="white">Achieving performance and operational excellence across a community of stakeholders</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                  <section  id="svot-situation" className="solutions ptb-100">
                      <div className="container list">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div animateIn="fadeInUp">
                                      <div className="section-title">
                                          <h1>In 2019, our client was running a $10B+ program for Global Health.</h1>
                                          <h3>They advanced global health goals by collaborating with donors, agencies, and host governments to - </h3>
                                          <div className="display-table">
                                              <div className="display-table-cell">
                                                <div className="container list">

                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <div className="glyph bullet">
                                                        <i class="icofont-drag2"/>
                                                        Quantify demand and unmet needs of the populations in more than 60 countries
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <div className="glyph bullet">
                                                        <i class="icofont-drag2"/>
                                                        Procure, ship, and ensure quality of pharmaceuticals, medical devices,and public health commodities
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <div className="glyph bullet">
                                                        <i class="icofont-drag2"/>
                                                        Measure performance of all participants, including suppliers, manufacturers, freight forwarders, and consultants
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <div className="glyph bullet">
                                                        <i class="icofont-drag2"/>
                                                        Monitor national health progress in HIV, maternal and reproductive health, and malaria
                                                      </div>
                                                    </div>
                                                  </div>


                                                </div>
                                              </div>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                      </div>
                  </section>

                  <section  id="svot-task" className="solutions pb-100">
                      <div className="container list">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div animateIn="fadeInUp">
                                      <div className="section-title">
                                          <h1>We were asked to drive performance and operational excellence within the community of stakeholders.</h1>
                                          <h3>Focusing on these core competencies - </h3>
                                          <div className="display-table">
                                              <div className="display-table-cell">
                                                <div className="container list">

                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <div className="glyph bullet">
                                                        <i class="icofont-star-alt-1"/>
                                                        Coordinated supply planning
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <div className="glyph bullet">
                                                        <i class="icofont-star-alt-1"/>
                                                        Global supply chain visibility
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <div className="glyph bullet">
                                                        <i class="icofont-star-alt-1"/>
                                                        Triangulating stock availability with health outcomes
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <div className="glyph bullet">
                                                        <i class="icofont-star-alt-1 "/>
                                                        Data-driven decision making
                                                      </div>
                                                    </div>
                                                  </div>

                                                </div>
                                              </div>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                      </div>
                  </section>



                  <section id="svot-action" className="solutions  pb-100">
                      <div className="container list">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div animateIn="fadeInUp">
                                      <div className="section-title">
                                          <h1>Over a 5-year period, we defined and elevated a single version of the truth across the entire ecosystem.</h1>
                                          <h3>While complying with a stringent cyber security mandate, we established an enterprise data management program and - </h3>
                                      </div>
                                      <div className="display-table">
                                          <div className="display-table-cell">
                                            <div className="container list">

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star-alt-2"/>
                                                    Led a strategic effort to define the roadmap and align with client's goals
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star-alt-2"/>
                                                  Developed a comprehensive logical reference model for the entire community
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star-alt-2"/>
                                                    Founded a Data Governance program to ensure data are trusted and used responsibly
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star-alt-2"/>
                                                    Architected and engineered data lake and data warehouse with data pipelines
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star-alt-2"/>
                                                    Implemented all cyber security controls and procedures and achieved a FedRAMP certification
                                                  </div>
                                                </div>
                                              </div>

                                            </div>
                                          </div>

                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </section>

                  <section id="svot-result" className="solutions pb-100">
                      <div className="container list">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div animateIn="fadeInUp">
                                      <div className="section-title">
                                        <h1>We supported 300 users across 10 globally accessible, scalable, and secure solutions.</h1>
                                        <h3>And for the first time ever, our client and the community had - </h3>
                                      </div>
                                      <div className="display-table">
                                          <div className="display-table-cell">
                                            <div className="container list">

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star"/>
                                                    End to end visibility of the entire supply chains
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star"/>
                                                    Self-service environment with visualization software and training
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star"/>
                                                    Global web-based access to effective coordinated supply planning with other donors
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star"/>
                                                    Dashboards with alerts to flag anomalies and enable management by exception
                                                  </div>
                                                </div>
                                              </div>


                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <div className="glyph bullet">
                                                    <i class="icofont-star"/>
                                                    Robust Master Data and a strong Data Governance program
                                                  </div>
                                                </div>
                                              </div>

                                            </div>
                                          </div>

                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </section>
                  {/* Footer: src/components/Footer.jsx */}
                  <Footer />
              </>
          );
        }
      }


export default SVOT;
