import React, { Component } from 'react';

// Import Component
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

/*
<h1 className="white">Setting a new bar for global supply chain execution and visibility</h1>
<h3 className="white">A Supply Chain Control Tower</h3>
*/
class SCCT extends Component {
    render() {
          return (
              <>
                <style>
                {`
                  h1  {
                    font-size: 1em;
                  }
                `}
                </style>
                  {/* NavBar: src/components/NavBar.jsx */}
                  <NavBar pageName="scct" />

                  <div id="top"
                      className="winning-story-ct-bg-img"

                  >
                  <div className="display-table">
                    <div className="display-table-cell">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-4 pb-200">
                            <div className="winning-story-info">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container list ptb-100">
                    <div className="row">
                        <div className="col-sm-4">
                          <h2 style={{fontSize:"2.5em"}}>Setting a new bar for global supply chain execution and visibility</h2>

                        </div>
                        <div className="col-sm-8">
                                <div className="section-title">
<h1>Our client was responsible for planning, purchasing, and distributing a $17B medical supply chain.  They implemented their program using 13 partners to execute globally resulting in a fractured view of their supply chain.
They needed a way to see the full, holistic, end to end view of these life saving medical supplies. They called it a <b>control tower</b>
<br/>
<br/>
<br/>
The challenge was to integrate the data from 13 very different systems and participants all with their own method of defining and storing data.
<br/>
<br/>
<br/>
IT2 led the development of a <b>strategy</b> to architect, engineer and execute the program.  We developed the <b>data</b> integration protocols, we deployed the solution on a <b>cloud</b> platform, we <b>planned</b> and <b>delivered</b>, and we did it all within a stringent data <b>privacy</b> and <b>security</b> framework
<br/>
<br/>
<br/>
Now thanks to the implementation of a commercial off the shelf supply chain product and a Snowflake data transformation and analytic platform, our client has an integrated ecosystem providing an accurate end-to-end view and operational control to drive efficiencies and address friction. A control tower.
</h1>

                                </div>

                        </div>

                      </div>
                    </div>
      {/*
                  <section  id="svot-situation" className="solutions ptb-100">
                      <div className="container list">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div animateIn="fadeInUp">
                                      <div className="section-title">
                                          <h1>Our client was responsible for planning, purchasing, and distributing a $17B medical supply chain.  They implemented their program using 13 partners to execute globally resulting in a fractured view of their supply chain.
They needed a way to see the full, holistic, end to end view of these life saving medical supplies. They called it a “control tower”
</h1>


                                      </div>
                                  </div>
                              </div>
                            </div>
                      </div>
                  </section>

                  <section  id="svot-task" className="solutions pb-100">
                      <div className="container list">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div animateIn="fadeInUp">
                                      <div className="section-title">
                                          <h1>
The challenge was to integrate the data from 13 very different systems and participants all with their own method of defining and storing data.
</h1>


                                      </div>
                                  </div>
                              </div>
                            </div>
                      </div>
                  </section>

                  <section id="svot-action" className="solutions pb-100">
                      <div className="container list">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div animateIn="fadeInUp">
                                      <div className="section-title">
                                          <h1>
IT2 led the development of a <b>strategy</b> to architect, engineer and execute the program.  We developed the <b>data</b> integration protocols, we deployed the solution on a <b>cloud</b> platform, we <b>planned</b> and <b>delivered</b>, and we did it all within a stringent data <b>privacy</b> and <b>security</b> framework
</h1>

                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </section>

                  <section id="svot-result" className="solutions pb-100">
                      <div className="container list">
                          <div className="row">
                              <div className="col-sm-12">
                                  <div animateIn="fadeInUp">
                                      <div className="section-title">
                                        <h1>
Now thanks to the implementation of a commercial off the shelf supply chain product and a Snowflake data transformation and analytic platform, our client has an integrated ecosystem providing an accurate end-to-end view and operational control to drive efficiencies and address friction. A control tower.
</h1>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </section>
*/}
                  {/* Footer: src/components/Footer.jsx */}
                  <Footer />
              </>
          );
        }
      }


export default SCCT;
