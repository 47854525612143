import React, { Component } from 'react';
import PropTypes from "prop-types";

// <div className="glyph text-center">
//     <i className={service.icon} />
// </div>
class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-12" key={index}>
            <div className="service-item">
                <h1 className="">{service.heading}</h1>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <>
        <style>
        {`
          h1  {
            font-size: 2em;
          },
          h3 { line-height: 1.5em }
        `}
        </style>
            <section id="services" className="services pb-50">
                <div className="container">
{/*
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">

                            <div
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                    </div>
*/}

<h3>
We take pride in delivering for our clients, with service offerings that showcase our deep expertise.
</h3>
                    <div className="mask" />
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "",
    servicesData: [
        {
          icon: "icofont-x",
          heading: "Strategy",
          description:
"Your Strategy lays out the business drivers - what you need and when, and why you need it. It defines the scope and lays out a roadmap of initiatives. It also evolves as your needs evolve, and a robust strategy is revisited periodically to ensure you stay competitive in a rapidly evolving market."
        },
        {
            icon: "icofont-x",
            heading: "Delivery",
            description:
"Delivery requires an obsession with the details, crystal clear objectives, and grounded in a proven methodology. It requires more than just the analytical and technical expertise - it requires cohesive teams with defined areas of responsibility."
        },
        {
            icon: "icofont-x",
            heading: "Cyber security",
            description:
"Cyber security is a story in two parts - Compliance and Engineering. Compliance is concerned with defining the policies and procedures for the organization and ensuring evidence of compliance, while Engineering is concerned with implementing the underlying security controls across the entire technology footprint."
        },
        {
            icon: "icofont-x",
            heading: "Governance",
            description:
"Every initiative requires governance - a focus on the process of management and delivery, and the growing of your teams' skills at every level. Great governance provides clear communication of progress, risks, and issues."
        },
    ]
};

export default Services;
