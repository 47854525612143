import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

class SCCTTeaser extends Component {
    render() {
        return (
            <>
            <section id="stars" className="cta-area cta-bg-img services">
                <div className="display-table">
                    <div className="display-table-cell">
                        <div className="container">
                            <div
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <div className="row">
                                    <div className="col-sm-6 col-md-7 col-lg-7">
                                          <h1 className="">Setting a new bar for global supply chain execution and visibility</h1>
                                          <h3 className="">A Supply Chain Control Tower</h3>
                                          <div className="center-wrap">
                                              <Link to={this.props.BtnLink} className="btn-a">
                                                  <div className="button">
                                                      {this.props.BtnName}
                                                      <i className="icofont-long-arrow-right" />
                                                  <div className="mask" /></div>
                                              </Link>
                                          </div>

                                    </div>

                                    <div className="col-sm-6 col-md-5 col-lg-5 cta-img">
                                        <img className="img-fluid" src={this.props.Image} alt="supply-chain-control-tower"/>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            </>
        );
    }
}
//Props Types
SCCTTeaser.propTypes = {
    BtnLink: PropTypes.string,
    BtnName: PropTypes.string,
    Image: PropTypes.string,

};

//Default Props
SCCTTeaser.defaultProps = {
    BtnLink: "/supply-chain-control-tower#navbar",
    BtnName: "See Our Impact",
    Image: require("../assets/img/supply-chain-3.png")
};


export default SCCTTeaser;
